import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import querystring from 'querystring';
import { setCookie } from '@umg/mira-widgets';

import { config } from '../config';
import PageTemplate from '../templates/page';
import { navigateToErrorPage } from '../routers';
import Page404 from './404';
import { PageProps } from '../interfaces/campaign-build.interfaces';
import { getPageBySlug } from '../services/pageService';
import { LoggerService } from '../util/logger/logger-service.class';
import packageJson from '../../package.json';
import { convertMapHeader } from '../util';

dayjs.extend(utc);
dayjs.extend(timezone);

const logger = LoggerService.getLogger({
  packageName: packageJson.name,
  packageVersion: packageJson.version,
  logPretty: config.LOG_PRETTY_PRINT
});

declare global {
  interface Window {
    ssr: boolean;
  }
}

const PageContent = (props: PageProps) => {

  React.useEffect(() => {
    if (props?.serverData?.isRedirectApp) {
      handleRedirectApp();
      return;
    }

    if (typeof window !== 'undefined') {
      window.ssr = true;
    }
  }, []);

  const handleRedirectApp = (): void => {
    try {
      if (window.location.search.length) {
        const params = querystring.parse(window.location.search.substring(1));
        if (params.error) {
          // creates a cookie that can be seen by any umusic subdomain
          setCookie('redirect-app-error', params.error.toString(), 30, '.umusic.com');
        }
      }
      window.close();
    } catch (error) {
      logger.info(`Error redirect app`);
      logger.error(error)
    }
  };

  if (props.serverData?.status !== 200) {
    if (props.serverData?.headers.location === '/404') {
      return <Page404 />
    } else {
      location.href = props.serverData?.headers ? props.serverData?.headers.location : '/404';
    }
    return;
  }

  return <PageTemplate {...props?.serverData} />;
};

export async function getServerData(context) {
  let url = context.params['*'];
  url = url.split('?')[0];

  // listen to all http requests
  // exclude webpack, .io, .ico, .json, .txt, .html files
  if (/(webpack|\.io|\.ico|\.json|\.txt|\.html|\..*)/.test(url) || !url) {
    return navigateToErrorPage();
    // return {
    //   props: {},
    // };
  }

  if (!url) {
    logger.error('Context params * didn\t have a valid url.');
    return navigateToErrorPage();
  }

  // logic for Spotify In-App
  // Check if request url includes "redirect-app", isRedirectApp will catch querystring param error, send it in a message and close window
  if (url.includes('redirect-app')) {
    return {
      props: {
        isRedirectApp: true,
      },
    };
  }

  context.url = `/${url}`;
  context.headers =  convertMapHeader({ context, headersToExclude: ['cookie']});
  logger.info(`headers: ${JSON.stringify(context.headers,null,2)}`);
  
  const host = context.headers['x-forwarded-host'] || context.headers?.host || null;
  logger.info(`<host>/<url>: ${host}/${url}`);

  if (!host) {
    logger.error('Context headers didn\t have a valid host.');
    return navigateToErrorPage();
  }

  
  const result = await getPageBySlug({ url, host, logger });

  // logger.warn(`Result:\n${JSON.stringify(result)}`);

  if (
    !result
    || !result?.page
    || !result?.campaign
    || result?.campaign?.unpublished
  ) {
    logger.error('Page / campaign data didn\'t exist or campaign was unpublised.');
    return navigateToErrorPage();
  }

  // execute handler for viewer request when is not local env, not preview and is not draft
  // ENABLE_VIEWER_REQUEST_HANDLER is not set anywhere so this is never executed for now
  // should we remove this line since seems this condition is never applied?
  // if (
  //   config.ENV !== 'local'
  //   && config.ENABLE_VIEWER_REQUEST_HANDLER
  // ) {
  //   // should redirect if page is locked or redirect to custom domain
  //   const redirectObj = handlerViewerRequest(context, result.campaign.data.slug, result.campaign.data.router, logger);

  //   if (redirectObj) {
  //     logger.info(`redirectObj: ${JSON.stringify(redirectObj,null,2)}`);
  //     return redirectObj;
  //   }
  // }

  return {
    props: {
      status: 200,
      isPreview: false,
      previewPages: [],
      pageContext: {
        pageId: result.page.id,
      },
      data: {
        allBuilderModels: {
          page: [
            {
              content: {
                id: result.page.id,
                data: {
                  ...result.page.data,
                  campaign: {
                    id: result.campaign.id,
                    value: result.campaign,
                  },
                },
              },
              localFiles: [],
            },
          ],
        },
      },
      children: null,
    },
  };
}

export default PageContent;
